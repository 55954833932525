let burger = document.querySelector('.js-btn')
let menu = document.querySelector('.js-menu')
let burgerClose = document.querySelector('.js-btn-close')
let body = document.querySelector('body')
let headerOverlay = document.querySelector('.js-header-overlay')

burger.addEventListener('click', function(){
  menu.classList.add('is-active')
  headerOverlay.classList.add('is-active')
  body.classList.add('no-scroll')

})

burgerClose.addEventListener('click', function(){
  menu.classList.remove('is-active')
  headerOverlay.classList.remove('is-active')
  body.classList.remove('no-scroll')
})

headerOverlay.addEventListener('click', function(event){
  if(event.target.className === headerOverlay.className){
    menu.classList.remove('is-active')
    headerOverlay.classList.remove('is-active')
    body.classList.remove('no-scroll')
  }
})